<template>
  <div class="container">
    <div class="pc-container">
      <div class="pc-iframe-container" style="--scale:1">
        <div class="pc-button-container">
          <a href="https://playgamesja.com" target="_blank" class="pc-button button-goto"></a>
          <div class="pc-button button-dialog" @click="showDialog"></div>
        </div>
        <div class="iframe">
          <Register :success="registerSuccess" v-show="!isShowDeposit" />
          <DepositEntrance :info="depositContent" :token="tokenContent" v-show="isShowDeposit" />
        </div>
      </div>
    </div>

    <div class="mobile-container">
      <div class="mobile-iframe-container" style="--scale:1">
        <div class="mobile-button-container">
          <a href="https://playgamesja.com" target="_blank" class="pc-button mobile-button mobile-button-goto"></a>
          <div class="pc-button mobile-button mobile-button-dialog" @click="showDialog"></div>
        </div>
        <div class="iframe">
          <Register :success="registerSuccess" v-show="!isShowDeposit" />
          <DepositEntrance :info="depositContent" :token="tokenContent" v-show="isShowDeposit" />
        </div>
      </div>
    </div>

    <div v-show="isShowDialog" class="dialog-container">
      <div class="dialog">
        <div class="close" @click="showDialog"></div>
        <div class="dialog-border">
          <div style="background-color: rgba(7, 18, 36, 0.90)">
            <div class="dialog-content">
              <div class="dialog-title">ようこそ！</div>
              シンプルで簡単！<br>
              いつでも、どこでも、入金するだけでOK！<br><br>

              5,000以上の多彩なゲーム種類を誇り、ライブゲームから人気のスロットまで幅広く取り揃えています。<br><br>

              K8最大の自慢はさまざまな決済方法が利用可能入出金サービスです。<br><br>

              特別なボーナスやキャンペーンもたくさん用意して、楽しさ満点で皆さんをお待ちしています◎<br><br>

              さあ！今すぐ最も信頼できるオンライン遊技機で冒険の扉を開きましょう！
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Register from '../components/Register/index.vue'
import DepositEntrance from '../components/DepositEntrance/index.vue'

export default {
  name: 'App',
  components: {
    Register,
    DepositEntrance
  },
  data() {
    return {
      isShowDialog: true,
      isShowDeposit: false,
      depositContent: {
        loginName: 'loginName',
        email: 'example@email.cpm',
      },
      tokenContent: {},
    }
  },
  watch: {
    isShowDialog: {
      handler(newValue) {
        document.body.style.overflow = newValue ? 'hidden' : ''
      },
    },
  },
  methods: {
    showDialog() {
      this.isShowDialog = !this.isShowDialog
    },
    registerSuccess(res, token) {
      this.depositContent = res
      this.tokenContent = token
      this.isShowDeposit = true
    }
  }
}
</script>

<style>
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 100;
  width: 100%;
  height: 100%;
}

.dialog {
  position: absolute;
  display: table;
  top: 15vh;
  left: 50%;
  transform: translateX(-50%);
  height: 30%;
  min-width: 460px;
  max-width: 560px;
  /*aspect-ratio: 1 / 0.775;*/
  border-radius: 24px;
  padding: 6px;
  box-shadow: 0 0 24px 0 rgba(6, 218, 237, 0.60), 0 0 5px 0 #E9FFFC inset;
  background: linear-gradient(to bottom, #2D95B6, #2DB674);
  color: #fff;
  font-size: 16px;
  line-height: 21px;
}

.close {
  position: absolute;
  z-index: 200;
  right: -9px;
  top: -9px;
  width: 38px;
  height: 38px !important;
  cursor: pointer;
  background-image: url('../assets/images/close.png');
  background-size: cover;
}

.close:hover {
  background-image: url('../assets/images/close-hover.png');
}

.dialog-border {
  border-radius: 20px;
  border: 10px solid rgba(7, 18, 36, 0.90);
  background-color: rgba(7, 18, 36, 0.90);
}

.dialog-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7FD7EE;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Kazesawa-Bold;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 16px;
}

.dialog-content {
  padding: 16px 40px;
  border: 1px solid #2D95B6;
  border-radius: 16px;
  font-family: Kazesawa;
  background: linear-gradient(180deg, #145874 0%, rgba(6, 55, 75, 0.00) 8.8%), rgba(7, 18, 36, 0.90);
}

iframe, .iframe {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  border: 1px solid #2D95B6;
  background: linear-gradient(180deg, #1D6886 0%, rgba(6, 55, 75, 0.00) 8.8%), rgba(7, 18, 36, 0.90);
  box-shadow: 0px 0px 24px 0px rgba(6, 218, 237, 0.60);
}

.pc-button-container {
  margin-top: calc(100% / var(--scale)*0.09);
  margin-bottom: calc(100% / var(--scale)*0.02);
  display: flex;
  justify-content:space-between;
  width: 100%;
}

.pc-button, .mobile-button {
  width: 26%;
  height: auto;
  max-width: 130px;
  max-height: 52px;
  cursor: pointer;
  background: transparent;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.mobile-button {
  width: 36%;
  min-width: 70px;
}

.button-goto, .mobile-button-goto {
  width: 26%;
  padding-top: 50px;
  background-image: url('../assets/images/button-goto.png');
}

.button-goto:hover, .mobile-button-goto:hover {
  background-color: transparent;
  background-image: url('../assets/images/button-goto-hover.png');
}

.mobile-button-goto {
  width: 36%;
  min-width: 70px;
}

.button-dialog, .mobile-button-dialog {
  width: 26%;
  padding-top: 50px;
  background-image: url('../assets/images/button-dialog.png');
}

.button-dialog:hover, .mobile-button-dialog:hover {
  background-image: url('../assets/images/button-dialog-hover.png');
}

.button-dialog:hover {
  background-image: url('../assets/images/button-dialog-hover.png');
}

.mobile-button-dialog {
  width: 36%;
  min-width: 70px;
}

.pc-container {
  background-image: url('../assets/images/bg-pc.jpg');
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
}

.pc-iframe-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% * 0.365);
  min-width: 370px;
  max-width: 693px;
  max-height: calc(100vw * 0.7);
  aspect-ratio: 1 / 1.1;
}
@supports not (aspect-ratio: 1 / 1.1) {
  .pc-iframe-container {
    height: calc(100vw * 0.365 * 1.1);
  }
}

/*mobile*/
.mobile-button-container {
  margin-top: calc(100% / var(--scale)*0.09);
  margin-bottom: calc(100% / var(--scale)*0.02);
  display: flex;
  justify-content:space-between;
  width: 100%;
}

.mobile-container {
  background-image: url('../assets/images/bg-mobile.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  width: 100vw;
  height: 2580px;

  display: none;
  justify-content: center;
  align-items: center;
}

.mobile-iframe-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 10px);
  min-width: 322px;
  max-width: 364px;
  max-height: 550px;
  aspect-ratio: 1 / 1.35;
}
@supports not (aspect-ratio: 1 / 1.35) {
  .mobile-iframe-container {
    height: calc(100vw * 1.35);
  }
}

@media screen and (min-aspect-ratio: 1/0.58) {
  .pc-container {
    background-size: cover;
  }
}
@supports not (min-aspect-ratio: 1/0.58) {
  .pc-container {
    background-size: cover;
  }
}

/*@media screen and (max-aspect-ratio: 1.2/1) {*/
/*  .container {*/
/*    overflow: unset;*/
/*  }*/
/*  .mobile-container {*/
/*    display: flex;*/
/*    align-items: flex-start;*/
/*    justify-content: center;*/
/*    padding-top: 87px;*/
/*  }*/
/*  .pc-container {*/
/*    display: none;*/
/*  }*/
/*}*/

@media (max-width: 767px) {
  .container {
    overflow: unset;
  }
  .mobile-container {
    display: flex;
  }
  .pc-container {
    display: none;
  }

  .dialog {
    min-width: 343px;
    max-width: 343px;
    font-size: 14px;
    padding: 4px;
  }

  .dialog-border {
    border: 8px solid rgba(7, 18, 36, 0.90);
  }

  .dialog-title {
    font-size: 22px;
    padding-bottom: 10px;
  }
  .dialog-content {
    padding: 12px 24px 30px 24px;
  }
}

@font-face {
  font-family: Kazesawa;
  src: url('../assets/fonts/Kazesawa-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Kazesawa-Bold;
  src: url('../assets/fonts/Kazesawa-Bold.ttf') format('truetype');
}

</style>
