<template>
  <div column style="width: 100%; height: 100%; overflow-y: auto; justify-content: flex-start" >
    <div row style="height: 81px">
      <span style="font-size: 24px; color: #fff; font-weight: 900; margin: 20px 0 10px">アカウントを作成</span>
    </div>

    <Tab :clickIndex="clickIndex" />

    <div v-if="tabIndex === 0" column class="form-container">
      <div row :class="['form', emailFocus && !emailErrMsg && 'focus', emailErrMsg && 'err', !emailErrMsg ? 'mb-16' : '']">
        <div :class="['input-container']" row >
          <img src="../../assets/images/svg/ic-email.svg" />
          <span class="required">*</span>
          <input
              ref="emailInput"
              placeholder="...@gmail.com"
              v-model="email"
              @focus="emailFocus = true"
              @blur="emailFocus = false"
          />
        </div>
        <div v-show="email" style="position: absolute; right: 0px" row pointer @click="clearEmail">
          <img src="../../assets/images/svg/ic-input-close.svg" />
        </div>
      </div>
      <div v-if="emailErrMsg" row class="err-msg">
        {{ emailErrMsg }}
      </div>

      <div row :class="['form', nameFocus && !nameErrMsg && 'focus', nameErrMsg && 'err', !nameErrMsg ? 'mb-16' : '']">
        <div :class="['input-container']" row >
          <img src="../../assets/images/svg/ic-user.svg" />
          <span class="required">*</span>
          <input
              ref="nameInput"
              placeholder="ユーザー名"
              v-model="name"
              @focus="nameFocus = true"
              @blur="nameFocus = false"
          />
        </div>
        <div v-show="name" style="position: absolute; right: 0px" row pointer @click="clearName">
          <img src="../../assets/images/svg/ic-input-close.svg" />
        </div>
      </div>
      <div v-if="nameErrMsg" row class="err-msg">
        {{ nameErrMsg }}
      </div>

      <div row :class="['form', passFocus && !passErrMsg && 'focus', passErrMsg && 'err', !passErrMsg ? 'mb-16' : '']">
        <div :class="['input-container']" row >
          <img src="../../assets/images/svg/ic-password.svg" />
          <span class="required">*</span>
          <input
              ref="passInput"
              :type="showPass ? 'text' : 'password'"
              placeholder="パスワード"
              v-model="pass"
              @focus="passFocus = true"
              @blur="passFocus = false"
          />
        </div>
        <div v-show="pass" style="position: absolute; right: 0px" row pointer @click="showPass = !showPass">
          <img v-if="!showPass" src="../../assets/images/svg/ic-eyes-on.svg" />
          <img v-else src="../../assets/images/svg/ic-eyes-off.svg" />
        </div>
        <div v-show="pass" style="position: absolute; right: 30px" row pointer @click="clearPass">
          <img src="../../assets/images/svg/ic-input-close.svg" />
        </div>
      </div>
      <div v-if="passErrMsg" row class="err-msg">
        {{ passErrMsg }}
      </div>

      <div row pointer @click="registerByMail"
           :class="['submit', ((name && !nameErrMsg) && (email && !emailErrMsg) && (pass && !passErrMsg)) ? 'submit-active' : '']">
        <template v-if="loading === true">
          <van-loading size="20" />
        </template>
        <template v-else>
          新規登録
        </template>
      </div>
    </div>

    <div v-if="tabIndex === 1" column class="form-container">
      <div row :class="['form', phoneErrMsg && 'err', !phoneErrMsg ? 'mb-16' : '']">
        <div :class="['input-container']" row >
          <div row>
            <img src="../../assets/images/svg/tab-mobile-active.svg" />
            <span class="required">*</span>
            <div>+81 </div>
          </div>
          <div style="width: 8px"></div>
          <input
              ref="phoneInput"
              placeholder="電話番号をご入力ください"
              v-model="phone"
          />
        </div>
        <div v-show="phone" style="position: absolute; right: 0px" row pointer @click="clearPhone">
          <img src="../../assets/images/svg/ic-input-close.svg" />
        </div>
      </div>
      <div v-if="phoneErrMsg" row class="err-msg">
        {{ phoneErrMsg }}
      </div>

      <div row :class="['form', codeFocus && !codeErrMsg && 'focus', codeErrMsg && 'err', !codeErrMsg ? 'mb-16' : '']">
        <div :class="['input-container']" row>
          <div style="width: 8px"></div>
          <input
              ref="codeInput"
              placeholder="確認コードを入力してください"
              v-model="code"
              @focus="codeFocus = true"
              @blur="codeFocus = false"
          />
        </div>
        <div style="position: absolute; right: 8px" row pointer @click="sendCode">
          <span :class="['send-code', (phone && !phoneErrMsg && !timerRunning) ? 'send-code-active' : '']">
            {{ timerRunning? `再送信(${countdown}s)`: '送信' }}
          </span>
        </div>
      </div>
      <div v-if="codeErrMsg" row class="err-msg">
        {{ codeErrMsg }}
      </div>

      <div row pointer @click="registerByPhone"
           :class="['submit', ((phone && !phoneErrMsg) && (code && !codeErrMsg)) ? 'submit-active' : '']">
        <template v-if="loading === true">
          <van-loading size="20" />
        </template>
        <template v-else>
          新規登録
        </template>
      </div>

    </div>

    <div pointer style="font-size: 12px; font-weight: bolder; margin: 16px 0" @click="gotoLogin">
      <span style="color: #5e6985">すでにアカウントをお持ちですか?</span><span style="color: #e6601b"> ログイン</span>
    </div>

  </div>
</template>

<script>
import Tab from './Tab/index.vue'
import { createAccount, sendCodeByPhone } from '../../apis/common'
import { showToast } from 'vant'
import { encryptRSA } from '../../utils'

export default {
  components: {
    Tab,
  },
  props: {
    success: {
      default: null,
      type: Function
    },
  },
  data() {
    return {
      tabIndex: 0,
      showPass: false,
      loading: false,

      name: '',
      email: '',
      pass: '',
      nameErrMsg: '',
      emailErrMsg: '',
      passErrMsg: '',
      nameFocus: false,
      emailFocus: false,
      passFocus: false,

      phone: '',
      code: '',
      phoneErrMsg: '',
      codeErrMsg: '',
      phoneFocus: false,
      codeFocus: false,
      countdown: 120,
      timerRunning: false,
    }
  },
  watch: {
    email(newValue) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const isValid = regex.test(newValue)
      this.emailErrMsg = isValid ? '' : 'メール形式が正しくありません'
    },
    name(newValue) {
      const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{4,20}$/
      const isValid = regex.test(newValue)
      this.nameErrMsg = isValid ? '' : '英数字または数字で始まり、4～20文字の組合せ'
    },
    pass(newValue) {
      const regex = /^[a-zA-Z0-9]{6,32}$/
      const isValid = regex.test(newValue)
      this.passErrMsg = isValid ? '' : '6～32文字のアルファベット、数字および記号を使用してください '
    },
    phone(newValue) {
      const regex = /^(0\d{9}|\d{9})$/
      const isValid = regex.test(newValue)
      this.phoneErrMsg = isValid ? '' : '正規な電話番号形式をご入力ください'
    },
    code(newValue) {
      const regex = /^\d{6}$/
      const isValid = regex.test(newValue)
      this.codeErrMsg = isValid ? '' : '確認コードを入力する必要があります'
    },
  },
  methods: {
    // encryptRSA(str) {
    //   const publicKey = `-----BEGIN PUBLIC KEY-----
    // MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCBLAq/icz49sEfsg1kNjeDcDbYl1OdwuNX9k7izxY1vDQx9wqJDh2wIMEIbXkRhM4nUyRqAoaqw0fU8HB8QrcUOVPIDT3AL7v3dGlo037gjVOLvKfgsHwJMhQFMjYv8gEWibPFxTlCG/pBiUVeu8N2E033Xxi2NbxEx6YolCBXGwIDAQAB
    //   -----END PUBLIC KEY-----`
    //
    //   const encrypt = new JSEncrypt()
    //   encrypt.setPublicKey(publicKey)
    //
    //   const res = encrypt.encrypt(str)
    //   return res
    // },
    async registerByMail() {
      if (!this.name || this.nameErrMsg || !this.email || this.emailErrMsg || !this.pass || this.passErrMsg) {
        return
      }

      if (this.loading)
        return

      const obj = {
        appId: this.name,
        loginName: this.name,
        password: encryptRSA(this.pass || ''),
        referCode: '',
        email: encryptRSA(this.email || ''),
        ts: 0,
        registerType: 1,
        fingerprint: this.name,
        autoLogin: 0,
        currentLoginName: '',
      }

      try {
        this.loading = true
        const res = await createAccount(obj)
        // console.log('registerByMail res')
        // console.log(res)
        this.loading = false

        if (res.success) {
          const p = {
            type: 'email',
            loginName: this.name,
            email: this.email,
            token: res.data.tokenInfo.access_token,
          }
          this.success(p, res.data.tokenInfo)

          this.$sensors.login(this.name)
        } else {
          if (res.msg)
            showToast(res.msg)
        }

        // this.$sensors.logout()
        // this.$sensors.identify(this.$sensors.quick('getAnonymousID')) // 重置匿名ID
        // this.$sensors.resetAnonymousIdentity()

        const sensorEmail = this.email.includes('@') ? this.email.split('@')[1] : this.email

        this.$sensors.track('RegisterResult', {
          source_channel: "",
          reg_channel: "邮箱",
          is_success: res.success,
          fail_reason: res.msg || '',
          email_type: sensorEmail,
          inviter_id: '',
          is_invited: false,
        })

        this.$sensors.track('LoginResult', {
          login_type: '邮箱',
          is_success: res.success,
          fail_reason: '',
          platform_type: window.screen.width >= 769 ? 'web' : 'h5',
        })

        // console.log('~~~~~')
        // console.log({
        //   source_channel: "",
        //   reg_channel: "邮箱",
        //   is_success: res.success,
        //   fail_reason: res.msg || '',
        //   email_type: sensorEmail,
        //   inviter_id: '',
        //   is_invited: false,
        // })
        // console.log( {
        //   login_type: '邮箱',
        //   is_success: true,
        //   fail_reason: '',
        //   platform_type: window.innerWidth >= 769 ? 'web' : 'h5',
        // })
      } catch (e) {
        this.loading = false
        console.log('registerByMail catch')
        console.log(e)
      }
    },
    async registerByPhone() {
      if (!this.phone || this.phoneErrMsg || !this.code || this.codeErrMsg) {
        return
      }

      if (this.loading)
        return

      const obj = {
        appId: this.phone,
        loginName: '',
        referCode: '',
        mobileCode: this.code,
        mobileNo: encryptRSA(this.phone || ''),
        ts: 0,
        registerType: 1,
        fingerprint: this.phone,
        autoLogin: 0,
        currentLoginName: '',
        phonePrefix: '0081',
        countryCode: 'JP',
      }

      try {
        this.loading = true
        const res = await createAccount(obj)
        this.loading = false
        // console.log('registerByPhone res')
        // console.log(res)

        if (res.success) {
          const p = {
            type: 'phone',
            loginName: res.data.loginName,
            email: '',
            token: res.data.tokenInfo.access_token,
          }
          this.success(p, res.data.tokenInfo)

          this.$sensors.login(res.data.loginName)
        } else {
          if (res.msg)
            showToast(res.msg)
        }

        this.$sensors.track('RegisterResult', {
          source_channel: "",
          reg_channel: "手机",
          is_success: res.success,
          fail_reason: res.msg || '',
          country_code_phone: "81",
          inviter_id: '',
          is_invited: false,
        })

        this.$sensors.track('LoginResult', {
          login_type: '手机',
          is_success: res.success,
          fail_reason: '',
          platform_type: window.screen.width >= 769 ? 'web' : 'h5',
        })

        // console.log('~~~~~')
        // console.log({
        //   source_channel: "",
        //   reg_channel: "手机",
        //   is_success: res.success,
        //   fail_reason: res.msg || '',
        //   country_code_phone: "81",
        //   inviter_id: '',
        //   is_invited: false,
        // })
        // console.log({
        //   login_type: '手机',
        //   is_success: res.success,
        //   fail_reason: '',
        //   platform_type: window.screen.width >= 769 ? 'web' : 'h5',
        // })
      } catch (e) {
        this.loading = false
        console.log('createAccount catch')
        console.log(e)
      }

    },
    init() {
      this.name = ''
      this.email = ''
      this.pass = ''
      this.nameErrMsg = ''
      this.emailErrMsg = ''
      this.passErrMsg = ''
      this.nameFocus = false
      this.emailFocus = false
      this.passFocus = false

      this.phone = ''
      this.code = ''
      this.phoneErrMsg = ''
      this.codeErrMsg = ''
      this.phoneFocus = false
      this.codeFocus = false
    },
    gotoLogin() {
      // const target = `https://uat-kratos-web.sarduse.com/?modal=login&tab=account&language=ja-JP&theme=playgames`
      const target = 'https://myk8.io/?modal=login&tab=account&language=ja-JP&theme=playgames'
      window.open(target, '_blank')
    },
    clearEmail() {
      this.email = ''
      this.emailErrMsg = 'メール形式が正しくありません'
      this.$refs.emailInput.focus()
    },
    clearName() {
      this.name = ''
      this.nameErrMsg = 'ユーザー名を入力してください '
      this.$refs.nameInput.focus()
    },
    clearPass() {
      this.pass = ''
      this.passErrMsg = '6～32文字のアルファベット、数字および記号を使用してください '
      this.$refs.passInput.focus()
    },
    clearPhone() {
      this.phone = ''
      this.phoneErrMsg = '正規な電話番号形式をご入力ください'
    },
    async sendCode() {
      if (!this.phone || this.phoneErrMsg)
        return

      if (this.timerRunning)
        return

      if (this.loading)
        return

      this.startTimer()

      const obj = {
        phone: encryptRSA(this.phone || ''),
        phonePrefix: '0081',
        purpose: 1,
      }

      try {
        this.loading = true
        const res = await sendCodeByPhone(obj)
        this.loading = false
        // console.log('sendCodeByPhone res')
        // console.log(res)

        if (!res.success) {
          if (res.msg)
            showToast(res.msg)
        }
      } catch (e) {
        this.loading = false
        console.log('sendCodeByPhone catch')
        console.log(e)
      }

      //create account
      // {
      //   "captchaType": 3,
      //     "captcha": "03AFcWeA5Ysls0T4ESJ0IGDnveZS0EU5ssIK9nmVG1IWqhHpeExGYIjFjkdXCTP9gPmiV7Z5ELZLjU6P9YqtPExShJO8sJ1yFmBmj5HY44X_c4SdNSTp_WGaPH4GW0c4JKOWyCbFmNJRzvE_AaNZ6om1RCy6C2Pjz3MEHH334MbN6B5-2XkfjctnG9EhmIaRgovVdumnyIYbnDWHpP79UwEiWtN-edcmvy3egjsEgs8wLNRYR1wjMGpzX7OviNLjaBroOwYRgWTQp6n3df8W7VJ_yYk3rpYP3sqy1pnkyh3ai4AawuRTYacn40_d5QuQ4pg1_OBGf09G2qMofpmCAofzL6fjr30NDsSk1oHFmSrU-jH3FEEDf6-KDTXvfercukAJnvqhIzScoIAWzvshU2lXeSZHDkoNSDAGsMjGQz5J0LWkx6kEQUBoxXeCr_omLSVWIYqHkuBiGGNoSGn-79NXg0lTsI9udiFYkIRY5D8vADNamg1jKNG4P2BsvrV0MPQKLGW5H5f6VGknvqZvKo8REmj2Tm6og7q4zUpZUHw_Kxy1b1J0JNbkqpt7N7vFjqcX-Q5-2WMxDKD9TvnLut_fxIm8DmYhqjhk1lKnfx8_EKQ7JhwerKsGpGC-guh6ibcnxT76inF6wOa0G4xIAlX9QwBFDydB1HYg",
      //     "googleResponse": "03AFcWeA5Ysls0T4ESJ0IGDnveZS0EU5ssIK9nmVG1IWqhHpeExGYIjFjkdXCTP9gPmiV7Z5ELZLjU6P9YqtPExShJO8sJ1yFmBmj5HY44X_c4SdNSTp_WGaPH4GW0c4JKOWyCbFmNJRzvE_AaNZ6om1RCy6C2Pjz3MEHH334MbN6B5-2XkfjctnG9EhmIaRgovVdumnyIYbnDWHpP79UwEiWtN-edcmvy3egjsEgs8wLNRYR1wjMGpzX7OviNLjaBroOwYRgWTQp6n3df8W7VJ_yYk3rpYP3sqy1pnkyh3ai4AawuRTYacn40_d5QuQ4pg1_OBGf09G2qMofpmCAofzL6fjr30NDsSk1oHFmSrU-jH3FEEDf6-KDTXvfercukAJnvqhIzScoIAWzvshU2lXeSZHDkoNSDAGsMjGQz5J0LWkx6kEQUBoxXeCr_omLSVWIYqHkuBiGGNoSGn-79NXg0lTsI9udiFYkIRY5D8vADNamg1jKNG4P2BsvrV0MPQKLGW5H5f6VGknvqZvKo8REmj2Tm6og7q4zUpZUHw_Kxy1b1J0JNbkqpt7N7vFjqcX-Q5-2WMxDKD9TvnLut_fxIm8DmYhqjhk1lKnfx8_EKQ7JhwerKsGpGC-guh6ibcnxT76inF6wOa0G4xIAlX9QwBFDydB1HYg",
      //     "googleRecaptchaId": 0,
      //     "btag": "",
      //     "affiliateId": "",
      //     "referCode": "",
      //     "mobileNo": "QwT4UyFozby7EFpI1/R9FbJKsuc6pzUNWBGUTBrnnCKs+kidBmNIGvX5S1nSTSgCJKZGnHB4COAEru522oepLoaC7SB1QWkdW+orMWXVYm90xH1zqbr7/ZMgNsV9BZDVToaRrD927zWrvlM4rioCSJLHU6MvdNnKjRLwFzZ0dpA=",
      //     "phonePrefix": "00886",
      //     "mobileCode": "304091"
      // }
    },
    startTimer() {
      if (this.timerRunning)
        return

      this.timerRunning = true
      const intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        } else {
          clearInterval(intervalId)
          this.timerRunning = false
        }
      }, 1000)
    },
    clickIndex(index) {
      this.tabIndex = index
      this.init()
    },
  }
}
</script>

<style scoped>
.form-container {
  max-width: 440px;
  width: 100%;
}

.form {
  border: 2px #2b3248 solid;
  background-color: #2b3248;
  border-radius: 6px;
  caret-color: #7d8fd8;
  color: #fff;
  width: 80%;
  justify-content: space-between;
  position: relative;
}
.err {
  border: 2px #FC0B36 solid;
}
.focus {
  border: 1px #7d8fd8 solid;
}

.input-container {
  position: relative;
  width: 100%;
}

.mb-16 {
  margin-bottom: 16px;
}

input {
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: 0;
  height: 44px;
  color: #fff;
  font-size: 15px;
  width: 100%;
}

img {
  padding: 0 12px;
}

.required {
  font-size: 14px;
  color: #e6601b;
  position: absolute;
  left: 32px;
  top: 5px;
}

.err-msg {
  justify-content: flex-start;
  width: 80%;
  color: #FC0B36;
  font-size: 10px;
  padding-top: 2px;
  margin-bottom: 16px;
}

.submit {
  background: linear-gradient(180deg, #2EAAD0 0%, #31C07D 100%);
  height: 46px;
  padding: 14px 30px;
  border-radius: 8px;
  width: 80%;
  opacity: 0.5;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.submit-active {
  opacity: 1;
  background: linear-gradient(180deg, #2EAAD0 0%, #31C07D 100%);
}

.submit-active:hover {
  background: linear-gradient(180deg, #2CC5F3 0%, #2CE38D 100%);
}

.send-code {
  font-size: 14px;
  color: #434b67;
}

.send-code-active {
  color: #b0bbcc;
}

@media (min-width: 1920px) {
  .form-container {
    width: 440px;
  }
  .form, .err-msg, .submit {
    width: 440px;
  }
}

</style>
