import request from './request'

const apiHost = 'https://myk8.io/_api_/'
// const apiHost = 'https://uat-kratos-web.sarduse.com/_api_/'

export const createAccount = (params = {}) => {
  return request.post(`${apiHost}api/v1/player/create_account`, {
    ...params,
  })
}

export const sendCodeByPhone = (params = {}) => {
  return request.post(`${apiHost}api/v1/phone/send_code_by_phone`, params)
}
