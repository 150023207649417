<template>
  <div column style="width: 100%; height: 100%; overflow-y: auto; justify-content: flex-start" class="showRegisterSuccess">
    <img src="../../assets/images/deposit.png" />
    <div class="s1">180% ウェルカムパッケージ</div>
    <div class="s2">初回入金で参加できる</div>

    <div class="role" column>
      <div v-if="info.type !== 'phone'" class="info-name">Hi~{{info.loginName}}</div>
      <div v-if="info.type !== 'phone'" class="info-email">{{info.email}}</div>
      <div class="submit" row pointer @click="gotoDeposit">今すぐ遊ぼう！</div>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'

export default {
  props: {
    info: {
      default: null,
      type: Object
    },
    token: {
      default: null,
      type: Object
    },
  },
  components: {
  },
  data() {
    return {
    }
  },
  watch: {
  },
  methods: {
    gotoDeposit() {
      const key = 'a83xu4u83'
      const str = JSON.stringify(this.token)
      const encrypted = CryptoJS.AES.encrypt(str, key).toString()
      const encode = encodeURIComponent(encrypted)
      console.log(encode)

      // const target = `https://uat-kratos-web.sarduse.com/myk8?language=ja-JP&theme=playgames&n=${encode}}`
      const target = `https://myk8.io/myk8?language=ja-JP&theme=playgames&n=${encode}}`
      window.open(target, '_blank')
    },
  }
}
</script>

<style scoped>
.s1 {
  background: linear-gradient(96deg,#92c1ff -1.2%,#a284ff 91.21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
  width: 192px;
}
.s2 {
  line-height: 28px;
  color: #8ef1ff;
  font-size: 14px;
  font-weight: 900;
}
.role {
  border-width: 1px 0;
  border-style: solid;
  border-color: #2B3248;
  max-width: 440px;
  width: 69%;
  min-width: 316px;
  padding: 32px;
  margin: 30px 0;
  background-color: #161b2a;
  border-radius: 10px;
}
.submit {
  background: linear-gradient(180deg, #2EAAD0 0%, #31C07D 100%);
  height: 40px;
  width: 220px;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
}
.submit:hover {
  background: linear-gradient(180deg, #2CC5F3 0%, #2CE38D 100%);
}
.info-name {
  font-size: 17px;
  font-weight: 900;
  color: #fff;
}
.info-email {
  font-size: 13px;
  color: #7d8ed8;
  font-weight: 400;
}
img {
  width: 351px;
  height: auto;
  margin-top: 24px;
}
</style>
