import axios from 'axios'
import md5 from 'js-md5'
import { Base64 } from 'js-base64'

const aid = 'fd3498ffe1454553b254380da8eb6832'
const qid = md5(Date.now() + Math.random().toString().split('.')[1].slice(0, 6))
const merchantCode = 'dcadcd4bb9'
const authorization = async () => {
  const json = ''
  const token = json?.access_token

  if (token) {
    return `bearer ${token}`
  } else {
    return 'Basic ' + Base64.encode('kratos-client:123456')
  }
}

const sign = (data, aid, qid, authorization) =>
  md5(
    JSON.stringify(data).split('').sort().join('').trim() +
      qid +
      aid +
      authorization,
  )

const instance = axios.create({
  baseURL: '',
})

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
// @ts-ignore
axios.defaults.headers['Accept'] = 'application/json'
axios.defaults.withCredentials = false

instance.interceptors.request.use(
  async config => {
    if (!config.url?.includes('k-img.picimgfield.com') && !config.url?.includes('cms.kgcloud88.net')) {
      // @ts-ignore
      config.headers['aid'] = aid
      // @ts-ignore
      config.headers['qid'] = qid
      // @ts-ignore
      config.headers['authorization'] = await authorization()
      // @ts-ignore
      config.headers['mcode'] = merchantCode
      if (config.data) {
        // @ts-ignore
        config.headers['sign'] = sign(config.data, aid, qid, config.headers['authorization'])
      }
      const fnp = '21D4F5F5EA29463D9BA7'
      // @ts-ignore
      config.headers['fnp'] = fnp.toString()
    }

    return config
  },
  error => {
    console.log(error) /* eslint-disable-line */
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // eslint-disable-next-line no-prototype-builtins
    if (response.data.hasOwnProperty('code')) {
      // console.log(response.data)
      // {"code": 1008, "data": null, "duration": 0, "msg": "The token is expired or invalid", "success": false}
      if (response.data.code === '1008' || response.data.code === 1008) {
        // configureStore().dispatch(UserActions.logout())
        // configureStore().dispatch(ActivityActions.logout())
      }
    }

    return response.data
  },
  function (error) {
    if (!error.response) {
      throw error
    }
    return Promise.reject(error)
  },
)

export default instance
