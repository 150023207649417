import JSEncrypt from "jsencrypt"
// MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCnj02qwBE8YQWX/jpUZtyrB5QD7ieBLlYbf8EbNE6z9/EZcV7/J/AnaClrFHYhlnqb3Tc2LSNU20ls9TKxateNV5lFxlr4Opr7tDOBXme9L3x7YliG4Mxfn8zG+MHIGx6L/O/nEw876xH9SCAMlijC9Cz2sgkkOpQ6rsdUZAohOwIDAQAB

export const encryptRSA = (str) => {
  const publicKey = `-----BEGIN PUBLIC KEY-----
   MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCBLAq/icz49sEfsg1kNjeDcDbYl1OdwuNX9k7izxY1vDQx9wqJDh2wIMEIbXkRhM4nUyRqAoaqw0fU8HB8QrcUOVPIDT3AL7v3dGlo037gjVOLvKfgsHwJMhQFMjYv8gEWibPFxTlCG/pBiUVeu8N2E033Xxi2NbxEx6YolCBXGwIDAQAB
      -----END PUBLIC KEY-----`

  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(publicKey)

  const res = encrypt.encrypt(str)
  return res
}
