<template>
  <div row class="tab-container">
    <div :class="['tab', activeIndex === 0 ? 'tab-active' : '']" row pointer @click="active(0)">
      <img v-if="activeIndex === 0" src="../../../assets/images/svg/tab-email-active.svg" id="tab-email" class="tab-email" />
      <img v-else src="../../../assets/images/svg/tab-email.svg" id="tab-email" class="tab-email" />
      <div :class="['title', activeIndex === 0 ? 'title-active' : '']">電子メール</div>
    </div>
    <div :class="['tab', activeIndex === 1 ? 'tab-active' : '']" row pointer @click="active(1)">
      <img v-if="activeIndex === 1" src="../../../assets/images/svg/tab-mobile-active.svg" style="width: 18px; height: 20px"/>
      <img v-else src="../../../assets/images/svg/tab-mobile.svg" style="width: 18px; height: 20px"/>
      <div :class="['title', activeIndex === 1 ? 'title-active' : '']">電話番号</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clickIndex: {
      default: null,
      type: Function
    },
  },
  data() {
    return {
      activeIndex: 0,
    }
  },
  methods: {
    active(index) {
      this.activeIndex = index
      this.clickIndex(index)
    }
  }
}
</script>

<style scoped>
.tab-container {
  background-color: #131724;
  padding: 4px;
  border-radius: 25px;
  /*color: #434b67;*/
  color: #5E6985;
  font-size: 14px;
  margin: 10px 0 25px;
}

.tab {
  padding: 8px 12px;
  position: relative;
}

.tab-active {
  color: #fff;
  background-color: #2b3248;
  border-radius: 21px;
}

.title-active {
  font-weight: bold;
}

img {
  margin-right: 6px;
}

.tab-email {
  width: 18px;
  height: 16px;
  color: #fff;
}
</style>
