import './assets/main.css'
import 'vant/lib/toast/style'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Toast, Loading } from 'vant'

var sensors = require('sa-sdk-javascript')
sensors.init({
  sdk_url: 'https://cdn.jsdelivr.net/npm/sa-sdk-javascript@1.15.1/sensorsdata.min.js',
  heatmap_url: 'https://cdn.jsdelivr.net/npm/sa-sdk-javascript@1.15.1/heatmap.min.js',
  name: 'sensors',
  server_url: 'https://sc.databroo.com:8443/sa?project=production',
  show_log: true,
})
sensors.quick('autoTrack')
Vue.prototype.$sensors = sensors

Vue.config.productionTip = false

Vue.use(Toast)
Vue.use(Loading)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
